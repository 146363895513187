/*------------------------------------------------------------------
-------------------------------------------------------------------*/
/*------------------------------------------------------------------
[Table of contents]

1. Body
	2. Header
		2.1. Navigation
		2.2. Menu
		2.3. Cart
	3. Banner
		3.1 Slick Slider
	4. Products
		4.1 Product Item
		4.2 Single Product
	5. Pricing
	6. Clients
	7. Instagram Feed
	8. User Dashboard
		8.1 User Profile
		8.2 Dashboard
		8.3 Orders Details
		8.4 Downloads
	9. Account Page
	10. Shop
		10.1 Checkout
		10.2 Shopping Cart
		10.3 Product Checkout Details
		10.4 Purchase Confirmation
	11.404 Page
	12. Contact
		12.1  Contact Form
		12.2 Contact Details
		12.3 Social Icons
	13.Footer

-------------------------------------------------------------------*/

/*=== BEGIN | CUSTOM PARAMETERS ===*/
// 'Nunito', 'Open Sans', 'Raleway', 'Playfair Display', 'Quicksand', 'Karla', 'Oswald', 'Montserrat Alternates', 'Montserrat', 'Merriweather', 'Roboto', 'Poppins'
$primary-font-family: 'Nunito', sans-serif;
$secondary-font-family: 'Source Sans Pro', sans-serif;

$blue-color: #4254fb;
$blue-secondary-color: #b41563;
$gray-color: #3f3a46;
$gray-secondary-color: #CEC7B5;
$green-color: #15a739;
$green-secondary-color: #53E6C8;
$orange-color: #fb5c42;
$orange-secondary-color: #911300;
$purple-color: #ce15b5;
$purple-secondary-color: #F2C9D8;
$red-color: #ca0000;
$red-secondary-color: #FF0D8B;

$theme: 'orange';// 'blue', 'gray', 'green', 'orange', 'purple', 'red'
/*=== END | CUSTOM PARAMETERS ===*/

/*=== BEGIN | NO CUSTOM PARAMETERS ===*/
$primary-color: $blue-color;
$secondary-color: $blue-secondary-color;
@if $theme == 'blue' {
  $primary-color: $blue-color;
  $secondary-color: $blue-secondary-color;
} @else if $theme == 'gray' {
  $primary-color: $gray-color;
  $secondary-color: $gray-secondary-color;
} @else if $theme == 'green' {
  $primary-color: $green-color;
  $secondary-color: $green-secondary-color;
} @else if $theme == 'orange' {
  $primary-color: $orange-color;
  $secondary-color: $orange-secondary-color;
} @else if $theme == 'purple' {
  $primary-color: $purple-color;
  $secondary-color: $purple-secondary-color;
} @else if $theme == 'red' {
  $primary-color: $red-color;
  $secondary-color: $red-secondary-color;
} @else {
  // Default value if $theme is not recognized
  $primary-color: $blue-color;
  $secondary-color: $blue-secondary-color;
}
$header-bg: "../images/header-bg-"+$theme+".png";
$nav-bg: "../images/nav-bg/"+$theme+".jpg";

/*=== MEDIA QUERY ===*/
@import url("https://fonts.googleapis.com/css?family=Poppins:400,700,800|Source+Sans+Pro:400|Catamaran:400,600,700");
@import url('https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i');
@import url('https://fonts.googleapis.com/css?family=Merriweather:400,400i,700,700i');
@import url('https://fonts.googleapis.com/css?family=Montserrat+Alternates:400,400i,700,700i');
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,400i,700,700i');
@import url('https://fonts.googleapis.com/css?family=Oswald:400,400i,700,700i');
@import url('https://fonts.googleapis.com/css?family=Karla:400,400i,700,700i');
@import url('https://fonts.googleapis.com/css?family=Quicksand:400,400i,700,700i');
@import url('https://fonts.googleapis.com/css?family=Playfair+Display:400,400i,700,700i');
@import url('https://fonts.googleapis.com/css?family=Raleway:400,400i,700,700i');
@import url('https://fonts.googleapis.com/css?family=Lato:400,400i,700,700i');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700,700i');
@import url('https://fonts.googleapis.com/css?family=Nunito:400,400i,700,700i');
html, body {
  height: 100%;
}

body {
  line-height: 1.7;
  font-family: $primary-font-family !important;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
}

p {
  font-family: $secondary-font-family !important;
  color: #000000;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: $primary-font-family !important;
  font-weight: 600;
  color: #040b43;
}

h4, .h4 {
  font-size: 22px;
}

h5, .h5 {
  font-size: 18px;
}

h6, .h6 {
  font-size: 16px;
}

.wrapper {
  padding: 0px 100px;
}

a,
button {
  -webkit-transition: all .3s;
  transition: all .3s;
}

a:hover,
a:focus,
a button:hover,
button:hover,
button:focus,
button button:hover {
  -webkit-transition: all .3s;
  transition: all .3s;
  text-decoration: none;
  outline: none;
  box-shadow: none;
}

a,
a:focus,
a:hover {
  text-decoration: none;
  outline: 0;
  color: #b32400;
}

.btn.active:focus,
.btn:active:focus,
.btn:focus {
  outline: 0;
  box-shadow: none !important;
}

.btn.focus,
.btn:focus {
  box-shadow: none !important;
}

.navbar-toggle .icon-bar {
  background: $primary-color;
}

input[type="email"],
input[type="password"],
input[type="text"],
input[type="tel"] {
  border-radius: 5px;
  box-shadow: none;
  height: 45px;
  background: linear-gradient(45deg, #f0e8f71c, #edd6ca1e);
  border-color: #380061;
  outline: none;
}

input[type="email"]:focus,
input[type="password"]:focus,
input[type="text"]:focus,
input[type="tel"]:focus {
  box-shadow: none;
  border: 1px solid $primary-color;
}

/* Estilos para el textarea */
textarea.form-control {
  width: 100%;
  padding: 10px; /* Ajusta el espaciado interno según tus necesidades */
  border: 1px solid #380061; /* Ajusta el grosor y color del borde según tus necesidades */
  resize: vertical; /* Permite redimensionar verticalmente el textarea */
  font-size: 16px; /* Ajusta el tamaño de la fuente según tus necesidades */
  background: linear-gradient(45deg, #f0e8f71c, #edd6ca1e);
}

/* Estilos para el placeholder */
textarea.form-control::placeholder {
  color: linear-gradient(45deg, #f0e8f71c, #edd6ca1e);; /* Color del texto del placeholder */
}

.form-control {
  box-shadow: none;
  height: 45px;
}

.form-control:focus {
  box-shadow: none;
  border: 1px solid $primary-color;
}

select.form-control {
  width: 100%;
  height: 40px; /* Ajusta la altura según tus necesidades */
  padding: 5px; /* Ajusta el espaciado interno según tus necesidades */
  border: 1px solid #380061; /* Ajusta el grosor y color del borde según tus necesidades */
  border-radius: 5px; /* Ajusta el radio de borde según tus necesidades */
  font-size: 14px; /* Ajusta el tamaño de la fuente según tus necesidades */
  line-height: 1.5; /* Ajusta el espaciado entre líneas según tus necesidades */
  background-color: linear-gradient(45deg, #f0e8f71c, #edd6ca1e); /* Ajusta el color de fondo según tus necesidades */
  color: #333; /* Ajusta el color del texto según tus necesidades */
}

/* Estilos para las opciones */
select.form-control option {
  background-color: linear-gradient(45deg, #f0e8f71c, #edd6ca1e); /* Ajusta el color de fondo de las opciones según tus necesidades */
  color: #333; /* Ajusta el color del texto de las opciones según tus necesidades */
}

/* Estilos para el hover del select */
select.form-control:hover {
  border-color: #999; /* Cambia el color del borde al pasar el mouse sobre el select */
}

.btn {
  font-size: 18px;
  letter-spacing: .5px;
  padding: 14px 35px;
  text-transform: capitalize;
  font-weight: 600;
  border-radius: 10px;
  border: 2px solid transparent;
}

.breadcrumb-item.active {
  color: #002f58;
}

.btn-main {
  background: linear-gradient(45deg, $primary-color, $secondary-color);
  color: #fff !important;
  border: none;
}

.btn-main:hover {
  background: linear-gradient(-45deg, $primary-color, $secondary-color);
  color: #040b43 !important;
}

.btn-main-transparent {
  background: transparent;
  border: none;
  border-color: linear-gradient(45deg, $primary-color, $secondary-color);
  color: $primary-color;
}

.btn-main-transparent:hover {
  border: none;
  background: linear-gradient(45deg, $primary-color, $secondary-color);
  color: #fff;
}

.btn-solid-border {
  border: none;
  background: #040b43;
  color: #fff;
}

.btn-solid-border:hover {
  background: #fff;
  color: #040b43 !important;
}

.btn-transparent {
  background: transparent;
  color: #040b43 !important;
  border: 2px solid #040b43;
}

.btn-transparent:hover {
  background: $primary-color;
  color: #fff !important;
  border-color: $primary-color;
}

.btn-black {
  background: #040b43;
  color: #fff;
  border-color: #040b43;
}

.btn-black:hover {
  background: $primary-color;
  color: #fff;
  border-color: $primary-color;
}

.btn-small {
  padding: 10px 25px;
}

.btn-extra-small {
  padding: 5px 10px;
  font-size: 12px;
}

.btn.active:focus,
.btn:active:focus,
.btn:focus {
  outline: 0;
  box-shadow: none;
}

.btn:focus {
  color: #ddd;
}

.bg-gray {
  background: #f9f9f9;
}

.section {
  padding: 80px 0;
}

.title {
  margin-bottom: 60px;
}

.title h2 {
  font-size: 38px;
  line-height: 48px;
  margin-bottom: 0px;
  font-family: $primary-font-family;
  text-transform: capitalize;
}

.social-media-icons ul li {
  display: inline-block;
}

.social-media-icons ul li a {
  font-size: 18px;
  color: #333;
  display: inline-block;
  padding: 7px 12px;
  color: #fff;
}

.social-media-icons ul li .twitter {
  background: #00aced;
}

.social-media-icons ul li .facebook {
  background: #3b5998;
  padding: 7px 18px;
}

.social-media-icons ul li .googleplus {
  background: #dd4b39;
}

.social-media-icons ul li .dribbble {
  background: #ea4c89;
}

.social-media-icons ul li .instagram {
  background: #bc2a8d;
}

.text-gray {
  color: #898a8f;
}

.bg-gray {
  background: #f2f3f5;
}

.bg-admin-home {
  background: #3b568c;
}

.mb-40 {
  margin-bottom: 35px;
}

.read-more {
  text-transform: uppercase;
  font-size: 14px;
  color: #040b43;
  border-bottom: 1px solid #040b43;
  font-weight: 600;
}

.read-more:hover {
  color: $primary-color !important;
  border-color: $primary-color;
}

.pagination {
  -webkit-box-pack: center;
          justify-content: center;
}

.pagination .page-item .page-link {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 0;
  border: 1px solid #dedede;
  text-align: center;
  margin-right: 6px;
  color: #000000;
}

.pagination .page-item .page-link:hover {
  background: $primary-color;
  color: #fff;
}

.pagination .page-item:first-child .page-link, .pagination .page-item:last-child .page-link {
  border-radius: 0;
}

.pagination .page-item.active .page-link {
  background: $primary-color;
  color: #fff;
  border-color: $primary-color;
}

.text-primary {
  color: $primary-color !important;
}

a.text-primary:focus,
a.text-primary:hover {
  color: $primary-color !important;
}

.content * {
  margin-bottom: 20px;
}

.content h1,
.content h2,
.content h3,
.content h4,
.content h5,
.content h6 {
  margin-bottom: 10px;
}

.content ol {
  padding-left: 15px;
}

.content ul {
  padding-left: 0;
}

.content ul li {
  position: relative;
  list-style-type: none;
  padding-left: 15px;
  margin-bottom: 10px;
}

.content ul li::before {
  position: absolute;
  content: "\f363";
  font-family: "therichpost-font";
  font-size: 20px;
  left: 0;
  top: -3px;
  color: $primary-color;
  -webkit-transition: .3s ease;
  transition: .3s ease;
}

.content table {
  text-align: left;
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  border: 1px solid linear-gradient(45deg, #f0e8f7, #f3c5ae);
}

.content table th,
.content table td {
  padding: .75rem;
  vertical-align: top;
  border: 1px solid linear-gradient(45deg, #f0e8f7, #f3c5ae);
  background-color: linear-gradient(45deg, #f0e8f7, #f3c5ae);
}

.content table thead {
  background: #380061;
}

.content table tbody {
  background: #000000;
}

.content table tbody td {
  text-align: left !important;
}

.content blockquote {
  padding: 20px;
  border-left: 5px solid $primary-color;
}

.content blockquote p {
  margin-bottom: 0;
  color: #040b43;
  font-style: italic !important;
}

.content pre {
  padding: 10px 20px;
  background: #000000;
}

.page-header {
  background: #f5f5f5;
  background: url($header-bg) no-repeat 50% 50%;
  background-size: cover;
  padding: 60px 0px;
  position: relative;
}

.page-header h1 {
  margin-bottom: 0px;
}

.page-header p {
  color: #040b43;
}

.page-wrapper {
  padding: 70px 0;
}

.search-wrap {
  position: relative;
  z-index: 1200;
  top: 20px;
  right: 16px;
  display: inline-block;
}

.overlay.open {
  visibility: visible;
  height: 100%;
  opacity: 1;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  overflow: hidden;
  width: 100%;
  height: 0;
  -webkit-transition: opacity .35s, visibility .35s, height .4s;
  transition: opacity .35s, visibility .35s, height .4s;
  opacity: 0;
  background: rgba(0, 0, 0, 0.98);
}

.overlay .search-form {
  position: relative;
  height: 100%;
  padding-top: 300px;
}

.overlay .search-form input {
  font-size: 56px;
  height: 60px;
  color: linear-gradient(45deg, #f0e8f7, #f3c5ae);
  border: none;
  background: transparent;
}

.search_toggle {
  cursor: pointer;
}

.toggle-wrap.active {
  position: relative;
  z-index: 10000;
  top: 0;
  right: 0;
  cursor: pointer;
  -webkit-transition: opacity .25s ease;
  transition: opacity .25s ease;
}

.toggle-wrap.active img.search-close {
  display: block;
}

.navigation {
  margin-bottom: 0;
  padding: 15px 0px;
}

.navbar-bg {
  background-image: url($nav-bg);
  background-repeat: no-repeat;
  background-size: cover;
}

.navigation .navbar-nav > li {
  padding: 0px 10px;
}

.navigation .navbar-nav > li.active a {
  color: #040b43;
}

.navigation .navbar-nav > li > a {
  color: #040b43;
  font-size: 17px;
  padding: 20px 15px;
  text-transform: capitalize;
  -webkit-transition: .2s ease-in-out 0s;
  transition: .2s ease-in-out 0s;
  letter-spacing: .5px;
  font-weight: 600;
}

.navigation .navbar-nav > li > a:hover, .navigation .navbar-nav > li > a:active, .navigation .navbar-nav > li > a:focus {
  background: none;
  color: $primary-color;
}

.navigation .navbar-nav .nav-link {
  color: #040b43;
}

.navigation .navbar-nav .nav-link:hover {
  color: $primary-color;
}

.navigation .navbar-nav .nav-link span {
  font-size: 8px;
  margin-left: 3px;
}

.navigation .nav .open > a {
  border: 1px solid transparent;
  background-color: transparent;
}

.navigation .dropdown-slide {
  -webkit-transition: all .35s ease;
  transition: all .35s ease;
}

.navigation .dropdown-slide .dropdown-menu {
  border: none;
  display: block;
  position: absolute;
  width: 200px;
  border-top: 3px solid $primary-color;
  left: auto;
  padding: 10px;
  border-radius: 0px;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.1);
  -webkit-transition: all .35s ease;
  transition: all .35s ease;
  margin-top: 20px;
}

.navigation .dropdown-slide .dropdown-menu li a {
  color: #222;
  font-size: 15px;
  border: 1px solid transparent;
  display: block;
  padding: 8px 16px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  -webkit-transition: .3s all;
  transition: .3s all;
}

.navigation .dropdown-slide .dropdown-menu li a:hover {
  background-color: #040b43;
  color: #fff;
}

.navigation .dropdown-slide .cart-dropdown {
  width: 290px !important;
  padding: 35px 20px;
  right: -40px;
  -webkit-transform: translateY(25px);
  transform: translateY(25px);
}

.dropdown-toggle::after {
  display: none;
}

.menu:hover {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 1px;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.8, 0, 0.2, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.8, 0, 0.2, 1);
  transition: transform 0.3s cubic-bezier(0.8, 0, 0.2, 1);
  transition: transform 0.3s cubic-bezier(0.8, 0, 0.2, 1), -webkit-transform 0.3s cubic-bezier(0.8, 0, 0.2, 1);
  -webkit-transform: scale3d(0, 1, 1);
          transform: scale3d(0, 1, 1);
  -webkit-transform-origin: 100% 50%;
          transform-origin: 100% 50%;
  background-color: #fff;
}

.top-menu li .cart-icon {
  position: relative;
}

.top-menu li a {
  color: #040b43;
}

.top-menu li a i {
  width: 35px;
  height: 35px;
  background: none;
  display: inline-block;
  color: #040b43;
  text-align: center;
  padding-top: 10px;
  border-radius: 100%;
}

.top-menu li span {
  font-weight: 600;
}

.cart-dropdown {
  box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.1);
  padding: 30px 0px;
}

.cart-dropdown .media {
  position: relative;
  border-bottom: 1px solid #dedede;
  padding-bottom: 15px;
  margin-bottom: 10px;
}

.cart-dropdown .media h6 {
  font-weight: 400;
}

.cart-dropdown img {
  width: 60px;
}

.cart-dropdown h4 {
  color: #040b43;
  font-weight: 300;
  font-size: 14px;
}

.cart-dropdown .cart-price {
  color: #7f7f7f;
  font-size: 12px;
  font-weight: 200;
}

.cart-dropdown .remove {
  padding: 1px 6px;
  position: absolute;
  right: 0;
  top: 0;
  color: #040b43 !important;
  font-size: 12px;
}

.cart-summary {
  margin-top: 10px;
  font-weight: 500;
  color: #040b43;
  font-size: 14px;
}

.cart-summary .total-price {
  float: right;
}

.nav-item.dropdown.dropdown-slide:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  margin-top: -5px;
  z-index: 9999;
}

.dropdown.cart-nav.dropdown-slide:hover .cart-dropdown {
  display: block;
  opacity: 1;
  z-index: 9999;
  visibility: visible;
  margin-top: -5px;
}

@media (max-width: 480px) {
  .navigation {
    padding: 15px 25px;
  }
  .navigation .navbar-nav .nav-link {
    border-bottom: 1px solid #dedede;
    padding: 10px 0px;
  }
}

@media (max-width: 400px) {
  .navigation {
    padding: 15px 25px;
  }
  .navigation .navbar-nav .nav-link {
    border-bottom: 1px solid #dedede;
    padding: 10px 0px;
  }
}

@media (max-width: 768px) {
  .navigation {
    padding: 15px 25px;
  }
  .navigation .navbar-nav .nav-link {
    border-bottom: 1px solid #dedede;
    padding: 10px 0px;
  }
}

@media (max-width: 992px) {
  .navigation {
    padding: 15px 25px;
  }
  .navigation .navbar-nav .nav-link {
    border-bottom: 1px solid #dedede;
    padding: 10px 0px;
  }
}

.navbar-nav .nav-link {
  color: #040b43;
}

.navbar-nav .nav-link:hover {
  color: $primary-color;
}

.navbar-nav .nav-link span {
  font-size: 8px;
  margin-left: 3px;
}

.navbar-nav .nav-link .nav .open > a {
  border: 1px solid transparent;
  background-color: transparent;
}

.slider {
  position: relative;
}

.slider .slider-item {
  background-size: cover;
}

.slider .slider-caption {
  padding: 160px 0px;
}

.slider .slider-caption span {
  opacity: .85;
  text-transform: capitalize;
}

.slider .slider-caption h1 {
  font-weight: 800;
  font-family: $secondary-font-family;
  font-size: 90px;
  line-height: 90px;
}

.slider .btn-main {
  position: relative;
  z-index: 2;
  -webkit-transition: all .35s ease-in-out;
  transition: all .35s ease-in-out;
}

.slider .btn-main:before {
  position: absolute;
  content: "";
  left: -10px;
  top: -10px;
  border: 2px solid $primary-color;
  width: 100%;
  height: 100%;
  -webkit-transition: all .25s ease-in-out;
  transition: all .25s ease-in-out;
}

.slider .btn-main:hover {
  background: #040b43;
  border-color: #040b43;
  color: #fff !important;
}

.slider .btn-main:hover:before {
  border-color: #040b43;
}

.slick-control-prev i {
  font-size: 20px;
  width: 60px;
  height: 60px;
  background: $primary-color;
  color: #fff;
  display: inline-block;
  text-align: center;
  padding-top: 20px;
  border-radius: 100%;
}

.slick-control-next i {
  font-size: 20px;
  width: 60px;
  height: 60px;
  background: $primary-color;
  color: #fff;
  display: inline-block;
  text-align: center;
  padding-top: 20px;
  border-radius: 100%;
}

.slick-control-prev, .slick-control-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  z-index: 999;
  opacity: 0;
  -webkit-transition: all .25s ease-in-out;
  transition: all .25s ease-in-out;
}

.slick-control-prev {
  left: 50px;
}

.slick-control-next {
  right: 50px;
}

.slider:hover .slick-control-prev,
.slider:hover .slick-control-next {
  opacity: 1;
}

.slick-dots {
  display: none !important;
}

@media (max-width: 480px) {
  .slider .slider-caption h1 {
    font-size: 28px;
    line-height: 28px;
  }
  .slider .slider-item {
    background: #f9f9f9 !important;
  }
  .slider .slider-caption {
    padding: 115px 0px;
  }
  .slick-control-prev, .slick-control-next {
    display: none !important;
  }
  .slick-dots {
    display: block !important;
  }
}

@media (max-width: 400px) {
  .slider .slider-caption h1 {
    font-size: 28px;
    line-height: 38px;
  }
  .slider .slider-item {
    background: #f9f9f9 !important;
  }
  .slider .slider-caption {
    padding: 115px 0px;
  }
  .slick-control-prev, .slick-control-next {
    display: none !important;
  }
  .slick-dots {
    display: block !important;
  }
}

@media (max-width: 768px) {
  .slider .slider-caption h1 {
    font-size: 49px;
    line-height: 57px;
  }
  .slider .slider-item {
    background: #f9f9f9 !important;
  }
  .slider .slider-caption {
    padding: 115px 0px;
  }
  .slick-control-prev, .slick-control-next {
    display: none !important;
  }
  .slick-dots {
    display: block !important;
  }
}

@media (max-width: 992px) {
  .slider .slider-caption h1 {
    font-size: 38px;
    line-height: 38px;
  }
  .slick-dots {
    display: block !important;
  }
}

.product {
  position: relative;
  -webkit-transition: .4s all;
  transition: .4s all;
  overflow: hidden;
}

.product:hover .product-hover-overlay {
  opacity: 1;
}

.product:hover .img-second {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.img-first {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  -webkit-transition: .2s linear;
  transition: .2s linear;
}

.img-second {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0px;
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
  -webkit-transition: .2s linear;
  transition: .2s linear;
}

.product-hover-overlay {
  position: absolute;
  bottom: 120px;
  left: 0px;
  width: 100%;
  -webkit-transition: .4s all;
  transition: .4s all;
  opacity: 0;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
          justify-content: center;
}

.product-hover-overlay a {
  background: #040b43;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  padding-top: 13px;
  -webkit-transition: .2s all;
  transition: .2s all;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  text-align: center;
  margin: 0px 1px;
}

.product-hover-overlay a:hover {
  background: $primary-color;
  color: #fff;
}

.product-title a {
  color: #040b43;
  letter-spacing: .5px;
}

.product-info {
  text-align: center;
}

.product-info .price {
  color: #777;
  font-size: 18px;
  font-family: $secondary-font-family;
}

.product-info del {
  margin-right: 8px;
}

.onsale {
  background: $primary-color;
  display: inline-block;
  color: #fff;
  position: absolute;
  top: 20px;
  right: 0px;
  padding: 5px 10px;
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: .5px;
}

.products-main .product-hover-overlay .btn, .products-shop .product-hover-overlay .btn {
  font-size: 14px;
  padding: 10px 29px;
  text-transform: capitalize;
  border-radius: 33px;
  font-weight: 600;
  letter-spacing: .5px;
  background: $primary-color;
  border-color: $primary-color;
  color: #fff;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.06);
}

.products-main .product-hover-overlay .btn:hover, .products-shop .product-hover-overlay .btn:hover {
  background: #040b43;
  border-color: #040b43;
  color: #fff !important;
}

.share-product li a {
  color: #444;
  font-size: 14px;
}

.single-product {
  padding: 60px 0 40px;
}

.single-product .breadcrumb {
  background: transparent;
}

.single-product .breadcrumb li {
  color: #040b43;
  font-weight: 200;
}

.single-product .breadcrumb li a {
  color: #040b43;
  font-weight: 200;
}

.single-product .product-pagination li {
  display: inline-block;
  margin: 0 8px;
}

.single-product .product-pagination li + li:before {
  padding: 0 8px 0 0;
  color: #ccc;
  content: "/\00a0";
}

.single-product .product-pagination li a {
  color: #040b43;
  font-weight: 200;
}

.single-product .product-pagination li a i {
  vertical-align: middle;
}

.single-product-slider .carousel-indicators {
  margin: 10px 0 0;
  overflow: auto;
  position: static;
  text-align: left;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
}

.single-product-slider .carousel-indicators li {
  background-color: transparent;
  border-radius: 0;
  display: inline-block;
  height: auto;
  margin: 0 !important;
  width: auto;
}

.single-product-slider .carousel-indicators li.active img {
  opacity: 1;
}

.single-product-slider .carousel-indicators li:hover img {
  opacity: 0.75;
}

.single-product-slider .carousel-indicators li img {
  display: block;
  opacity: 0.5;
}

.single-product-details .product-price {
  font-family: $secondary-font-family;
}

.single-product-details .product-price del {
  color: #777;
}

.single-product-details .cart .btn {
  padding: 8px 12px;
}

.single-product-details .cart .btn:hover {
  background: #040b43;
  border-color: #040b43;
  color: #fff !important;
}

.single-product-details .product-meta-title {
  width: 20%;
}

.single-product-details .color-swatches a {
  display: inline-block;
  width: 36px;
  height: 36px;
  margin-right: 5px;
}

.single-product-details .product-size .form-control {
  display: inline-block;
  width: 130px;
  letter-spacing: 2px;
  text-transform: uppercase;
  border: 1px solid #e5e5e5;
  border-radius: 0px;
  box-shadow: none;
}

.single-product-details .product-category ul {
  width: 140px;
  display: inline-block;
}

.single-product-details .product-category ul li {
  display: inline-block;
  margin: 5px;
}

.single-product-details .product-quantity .product-quantity-slider {
  width: 140px;
}

.single-product-details .product-quantity .product-quantity-slider input {
  width: 130px;
}

.single-product-details .product-category a {
  color: #040b43;
  margin-right: 5px;
}

.single-product-details .product-share a {
  color: #040b43;
  margin-right: 10px;
}

.single-product-details .product-share a:hover {
  color: $primary-color;
}

.product-info-tabs {
  border-bottom: 1px solid #dedede;
}

.product-info-tabs .nav-tabs a {
  color: #040b43;
  font-size: 22px;
  font-family: $secondary-font-family;
  font-weight: 700;
}

.product-info-tabs .nav-tabs .nav-link {
  border: 1px solid #dedede;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  color: #ccc;
}

.product-info-tabs .nav-tabs .nav-link.active {
  color: #040b43;
}

.product-info-tabs .nav-tabs {
  border-bottom: 0px;
}

.widget .btn {
  margin: 0px;
  padding: 5px 16px;
  color: #fff;
  font-size: 16px;
}

.review-block span i {
  color: #EABE12;
}

.review-block img {
  width: 120px;
}

.review-comment .form-control {
  background: #eee;
  border-color: #eee;
}

.info-desc li {
  margin-bottom: 10px;
}

.info-desc strong {
  width: 20%;
}

.info-desc span {
  margin-left: 40px;
}

.starrr i {
  cursor: pointer;
  color: #EABE12;
  font-size: 30px;
}

.team-member p {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.instagram-feed a {
  margin: 6px;
  margin-right: 10px;
  display: inline-block;
  margin-bottom: 10px;
  width: 23.5%;
}

@media (max-width: 768px) {
  .instagram-feed a {
    width: 49%;
    margin: 3px;
  }
}

@media (max-width: 480px) {
  .instagram-feed a {
    width: 100%;
    margin: 3px;
  }
}

.instagram-feed a:hover img {
  -webkit-filter: grayscale(10);
          filter: grayscale(10);
}

.instagram-feed a img {
  width: 100%;
}

.checkout-form .form-group {
  position: relative;
  margin-bottom: 8px;
}

.checkout-form .form-group label {
  display: block;
}

.checkout-form .form-group input {
  border-radius: 0;
  height: 45px;
}

.checkout-form .checkout-country-code .form-group {
  float: left;
}

.checkout-form .checkout-country-code .form-group:first-child {
  width: calc(45% - 2px);
  margin-right: 4px;
}

.checkout-form .checkout-country-code .form-group:last-child {
  width: calc(55% - 2px);
}

.shopping.cart .product-list .table > tbody > tr > td {
  vertical-align: middle;
}

.shopping.cart .product-list .table img {
  max-width: 70px;
  height: auto;
}

.shopping.cart .product-list .product-name a {
  color: #040b43;
  font-weight: 600;
}

.shopping.cart .product-list .qty {
  width: 65px;
  text-align: center;
  border-color: #eee;
  border: 1px solid #eee;
  padding: 5px;
}

.shopping.cart .product-list .input-text.form-control {
  width: 30%;
  display: inline-block;
  height: 50px;
}

.order-details {
  padding: 30px 0px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.order-details li {
  line-height: 1;
  margin-right: 2em;
  margin-left: 0;
  padding-right: 4em;
  color: #777;
  padding-left: 0;
  list-style-type: none;
  text-transform: uppercase;
  border-right: 1px dashed #d3ced2;
}

.order-details li h5 {
  margin-top: 15px;
  text-transform: capitalize;
}

.slider.slider-horizontal {
  height: 15px;
  margin-bottom: 20px;
  margin-left: 5px;
}

.slider.slider-horizontal .slider-track {
  background: #ddd;
  height: 7px;
  box-shadow: none;
  margin-top: -4px;
}

.slider-selection {
  background: $primary-color;
  box-shadow: none;
  border-radius: 0;
}

.slider-handle {
  width: 14px;
  height: 14px;
  background-color: #fff;
  border: 3px solid #ff5f5f;
}

.slider-handle {
  background: #fff;
}

.product-categories li {
  margin: 10px 0px;
}

.product-categories li a {
  color: #040b43;
}

.custom-control-label.sky-blue::before,
.custom-control-input:checked ~ .custom-control-label.sky-blue::before {
  background-color: #00f;
}

.custom-control-label.red::before,
.custom-control-input:checked ~ .custom-control-label.red::before {
  background-color: #ff5c5c;
}

.custom-control-label.dark::before,
.custom-control-input:checked ~ .custom-control-label.dark::before {
  background-color: #343a40;
}

.custom-control-label.magenta::before,
.custom-control-input:checked ~ .custom-control-label.magenta::before {
  background-color: #dcbba8;
}

.custom-control-label.yellow::before,
.custom-control-input:checked ~ .custom-control-label.yellow::before {
  background-color: #ffc107;
}

.color-checkbox .custom-control-label {
  cursor: pointer;
}

.color-checkbox .custom-control-label::before, .color-checkbox .custom-control-label::after {
  height: 30px;
  width: 30px;
  border: 0;
}

.popular-products-item {
  margin-bottom: 20px;
}

.popular-products-item img {
  max-width: 70px;
}

.popular-products-item .price {
  color: #777;
}

.widget-sizes .custom-checkbox {
  margin-bottom: 10px;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: $primary-color;
  border-color: $primary-color;
}

.widget-categories ul,
.widget-links ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.widget-categories ul > li,
.widget-links ul > li {
  position: relative;
  margin-bottom: 5px;
  padding-left: 14px;
}

.widget-categories ul > li:last-child,
.widget-links ul > li:last-child {
  margin-bottom: 0;
}

.widget-categories ul > li::before,
.widget-links ul > li::before {
  display: block;
  position: absolute;
  top: 12px;
  left: 0;
  width: 0;
  height: 0;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  transition: -webkit-transform .35s;
  -webkit-transition: -webkit-transform .35s;
  transition: transform .35s;
  transition: transform .35s, -webkit-transform .35s;
  border-top: 4px dashed;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  color: #000000;
  content: '';
}

.widget-categories ul > li > a,
.widget-links ul > li > a {
  display: inline-block;
  -webkit-transition: color .3s;
  transition: color .3s;
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
}

.widget-categories ul > li > a:hover,
.widget-links ul > li > a:hover {
  color: $primary-color;
}

.widget-categories ul > li.active > a,
.widget-links ul > li.active > a {
  color: $primary-color;
}

.widget-categories ul > li > span,
.widget-links ul > li > span {
  margin-left: 4px;
  color: #000000;
}

.widget-categories ul > li.has-children ul,
.widget-links ul > li.has-children ul {
  border-left: 1px solid #dee5ea;
}

.widget-categories ul > li.has-children ul li::before,
.widget-links ul > li.has-children ul li::before {
  top: 14px;
  width: 8px;
  height: 1px;
  -webkit-transform: none;
  transform: none;
  border: 0;
  background-color: #dee5ea;
  color: transparent;
}

.widget-categories ul > li.has-children ul li a,
.widget-links ul > li.has-children ul li a {
  font-size: 13px;
}

.widget-categories ul > li.has-children ul ul > li,
.widget-links ul > li.has-children ul ul > li {
  margin-bottom: 0;
}

.widget-categories ul > li.has-children > ul,
.widget-links ul > li.has-children > ul {
  max-height: 0;
  -webkit-transition: max-height .6s;
  transition: max-height .6s;
  overflow: hidden;
}

.widget-categories ul > li.has-children.expanded::before,
.widget-links ul > li.has-children.expanded::before {
  -webkit-transform: rotate(0);
  transform: rotate(0);
}

.widget-categories ul > li.has-children.expanded > ul,
.widget-links ul > li.has-children.expanded > ul {
  max-height: 1000px;
}

.page-404 {
  padding: 100px 0 120px;
}

.page-404 h1 {
  font-size: 300px;
  line-height: 300px;
}

@media (max-width: 480px) {
  .page-404 h1 {
    font-size: 130px;
    line-height: 150px;
  }
}

@media (max-width: 400px) {
  .page-404 h1 {
    font-size: 100px;
    line-height: 100px;
  }
}

@media (max-width: 768px) {
  .page-404 h1 {
    font-size: 150px;
    line-height: 200px;
  }
}

.page-404 h2 {
  font-size: 20px;
}

.error-content {
  background: #f9f9f9;
  padding: 60px 0px;
}

#map {
  width: 100%;
  height: 400px;
}

.text-color {
  color: $primary-color;
}

.feature {
  background: #f9f9f9;
}

.feature-item {
  padding: 60px 30px 50px 30px;
}

.feature-item i {
  font-size: 30px;
  float: left;
  color: $primary-color;
}

.feature-item p {
  margin-bottom: 0px;
  font-size: 14px;
}

.feature-item .content {
  padding-left: 60px;
}

.feature-item h5 {
  margin-bottom: 0px;
}

.category-box {
  position: relative;
}

.category-box .content {
  margin-top: 15px;
}

.category-box .content h3 {
  margin-bottom: 0px;
  font-weight: 600;
}

.category-box .content span {
  font-family: $secondary-font-family;
  font-weight: 600;
}

.category-box a {
  color: #040b43;
}

.category-box img {
  width: 100%;
}

@media (max-width: 992px) {
  .instagram-slider {
    overflow: hidden;
  }
}

.instagram-slider .slick-list {
  overflow: visible;
}

.instagram-post {
  position: relative;
}

.instagram-post::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: .2s ease;
  transition: .2s ease;
}

.instagram-post ul {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: .2s ease;
  transition: .2s ease;
}

.instagram-post ul a {
  -webkit-transition: .2s ease;
  transition: .2s ease;
}

.instagram-post ul a:hover {
  color: $primary-color !important;
}

.instagram-post:hover::before {
  visibility: visible;
  opacity: 1;
}

.instagram-post:hover ul {
  visibility: visible;
  opacity: 1;
}

.letter-spacing {
  letter-spacing: 1px;
}

.ads {
  background: rgba(252, 114, 104, 0.06);
  background-size: cover;
  position: relative;
}

.ads .ads-content h2 {
  font-size: 60px;
  font-weight: 700;
  line-height: 60px;
}

.ads .ads-content p {
  margin-bottom: 30px;
}

.ads .ads-content span.deal {
  background: rgba(255, 255, 255, 0.3);
  padding: 6px 15px;
  color: #222;
}

.ads .btn-main:hover {
  background: #fff;
  border-color: #fff;
}

.ads .syotimer.large .syotimer-cell {
  height: 100px;
  width: 100px;
}

.ads .syotimer-cell {
  display: inline-block;
  height: 100px;
  width: 115px;
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.7);
  text-align: center;
  padding-top: 15px;
  margin-right: 10px;
}

.ads .syotimer-cell__unit {
  color: #777;
}

.ads .syotimer-cell__value {
  font-weight: 600;
  font-size: 45px;
  line-height: 45px;
}

.text-md {
  font-size: 20px;
}

.feature-section {
  border-bottom: 1px solid #eee;
}

.feature-block {
  padding: 40px 0px;
}

.feature-block i {
  float: left;
  font-size: 35px;
  color: $primary-color;
  opacity: .7;
}

.feature-block .content {
  padding-left: 60px;
}

.feature-block .content h5 {
  font-size: 20px;
  margin-bottom: 0px;
}

.feature-block .content p {
  margin-bottom: 0px;
}

.feature-block.active {
  background: #fff;
}

.contact-short-info i {
  font-size: 20px;
}

.contact-short-info li {
  margin-bottom: 10px;
}

.contact-form .form-control {
  border-color: #f9f9f9;
  background: linear-gradient(45deg, #f0e8f7, #f3c5ae);
}

table.shop_table_responsive {
  border: 1px solid linear-gradient(45deg, #f0e8f7, #f3c5ae);
}

.cart-info h4 {
  font-family: $secondary-font-family;
}

.cart-info h5 {
  font-weight: 400;
}

.cart-info ul span {
  font-family: $secondary-font-family;
  font-weight: 700;
}

.cart-info ul li {
  border-bottom: 1px solid #dedede;
}

.amount {
  font-family: $secondary-font-family;
}

.user-dashboard .nav-pills {
  border: 1px solid #eee;
}

.user-dashboard .nav-pills .nav-link {
  color: #040b43;
  font-weight: 600;
  padding: 10px 20px;
}

.user-dashboard .nav-pills .nav-link.active, .user-dashboard .nav-pills .show > .nav-link {
  background-color: $primary-color;
  color: #fff;
  border-radius: 0px;
}

.cat-item {
  position: relative;
}

.cat-item .item-info {
  position: absolute;
  right: 35px;
  top: 25%;
}

.cat-item .read-more {
  color: $primary-color;
  border-color: $primary-color;
}

.cat-item p {
  font-size: 18px;
}

.widget-featured-entries h4 {
  display: block;
  position: relative;
  border-bottom: 1px solid #e7e7e7;
}

.widget-featured-entries h4:after {
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 5rem;
  height: 2px;
  background-color: $primary-color;
  content: '';
}

.featured-entry-title a {
  color: #040b43;
  font-weight: 400;
}

.featured-entry-meta {
  color: #040b43;
}

.faq h4 {
  border-bottom: 1px solid #dedede;
  padding: 10px 0px;
}

.login-form .heading {
  margin-bottom: 70px;
}

.login-form h2 {
  font-size: 60px;
  font-weight: 400;
  line-height: 1.5;
}

.login-form .form-control {
  background: transparent;
  height: 55px;
  padding-left: 20px;
}

.login-form .btn {
  font-size: 20px;
}

.login-form .btn:hover {
  background: $primary-color;
}

@media (max-width: 480px) {
  .order-details li {
    width: 100%;
    border-right: 0px;
    padding: 20px 20px;
    background: #f9f9f9;
  }
  table.shop_table_responsive thead {
    display: none;
  }
  table.cart tr:first-child td.product-remove {
    border-top-width: 1px;
  }
  table.shop_table_responsive tr td {
    display: block;
    text-align: right !important;
  }
  table.shop_table_responsive tr td::before {
    content: attr(data-title) ": ";
    font-weight: 700;
    float: left;
  }
  .ads .syotimer-cell {
    margin-bottom: 10px;
  }
  .ads {
    background: #eba1ae;
  }
}

@media (max-width: 400px) {
  .order-details li {
    width: 100%;
    border-right: 0px;
    padding: 20px 20px;
    background: #f9f9f9;
  }
  table.shop_table_responsive thead {
    display: none;
  }
  table.cart tr:first-child td.product-remove {
    border-top-width: 1px;
  }
  table.shop_table_responsive tr td {
    display: block;
    text-align: right !important;
  }
  table.shop_table_responsive tr td::before {
    content: attr(data-title) ": ";
    font-weight: 700;
    float: left;
  }
  .ads .syotimer-cell {
    margin-bottom: 10px;
  }
  .ads {
    background: #eba1ae;
  }
}

@media (max-width: 768px) {
  .order-details li {
    width: 100%;
    border-right: 0px;
    padding: 20px 20px;
    background: #f9f9f9;
  }
  table.shop_table_responsive thead {
    display: none;
  }
  table.cart tr:first-child td.product-remove {
    border-top-width: 1px;
  }
  table.shop_table_responsive tr td {
    display: block;
    text-align: right !important;
  }
  table.shop_table_responsive tr td::before {
    content: attr(data-title) ": ";
    font-weight: 700;
    float: left;
  }
  .ads .syotimer-cell {
    margin-bottom: 10px;
  }
  .ads {
    background: #eba1ae;
  }
}

@media (max-width: 992px) {
  .order-details li {
    width: 100%;
    border-right: 0px;
    padding: 20px 20px;
    background: #f9f9f9;
  }
}

.footer {
  padding-top: 80px;
  padding-bottom: 40px;
  background: #f9f9f9;
}

.footer-widget ul li {
  margin-bottom: 15px;
}

.footer-widget ul li a {
  color: #777;
}

.footer-widget ul li a:hover {
  color: $primary-color;
}

.social-icons a {
  width: 40px;
  height: 40px;
  border: 1px solid #dedede;
  display: inline-block;
  text-align: center;
  color: #040b43;
  padding-top: 8px;
  border-radius: 100%;
}

.footer-btm-links a {
  color: #040b43;
  margin-left: 8px;
}

.copyright {
  color: #040b43;
}
.slick-prev, .slick-next {
  font-size: 0!important;
  line-height: 0!important;
  position: absolute;
  top: 50%!important;
  display: block;
  padding: 0!important;
  -webkit-transform: translate(0, -50%)!important;
  transform: translate(0, -50%)!important;
  cursor: pointer!important;
  color: transparent!important;
  border: none!important;
  outline: none!important;
  background: transparent!important;
  z-index: 999!important;
  opacity: 0;
  -webkit-transition: all .25s ease-in-out!important;
  transition: all .25s ease-in-out!important;
}
.slick-prev:before, .slick-next:before
{

  font-size: 20px;
    width: 20px;
    height: 20px;
    background: linear-gradient(45deg, $primary-color, $secondary-color);
    color: #fff;
    display: inline-block;
    text-align: center;
    padding-top: 1px;
    border-radius: 100%;

}
.slick-next {
  right: 85px!important;
}
.slick-prev {
  left: 50px!important;
}
.slick-slider:hover .slick-prev, .slick-slider:hover .slick-next{
  opacity: 1!important;
}
/*the container must be positioned relative:*/
.custom-select {
  position: relative;
  font-family: Arial;
}

.custom-select select {
  display: none; /*hide original SELECT element:*/
}

.select-selected {
  background-color: DodgerBlue;
}

/*style the arrow inside the select element:*/
.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

/*point the arrow upwards when the select box is open (active):*/
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

/*style the items (options), including the selected item:*/
.select-items div,.select-selected {
  color: #ffffff;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
  user-select: none;
}

/*style items (options):*/
.select-items {
  position: absolute;
  background-color: DodgerBlue;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

/*hide the items when the select box is closed:*/
.select-hide {
  display: none;
}

.select-items div:hover, .same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}

/*# sourceMappingURL=maps/style.css.map */

.shipping-pending {
  background: #971e00;
  display: inline-block;
  color: #fff;
  position: absolute;
  top: 20px;
  right: 0px;
  padding: 5px 10px;
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: .5px;
}

.shipping-checked {
  background: #f5dc00;
  display: inline-block;
  color: rgb(7, 7, 7);
  position: absolute;
  top: 20px;
  right: 0px;
  padding: 5px 10px;
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: .5px;
}

.shipping-sended {
  background: #00420e;
  display: inline-block;
  color: #fff;
  position: absolute;
  top: 20px;
  right: 0px;
  padding: 5px 10px;
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: .5px;
}

.shipping-receibed {
  background: #222222;
  display: inline-block;
  color: #fff;
  position: absolute;
  top: 20px;
  right: 0px;
  padding: 5px 10px;
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: .5px;
}
